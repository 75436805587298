import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1>{`Packages`}</h1>
    <p>{`Css Stats consists of a few core libraries that can be used on their own if desired.`}</p>
    <ul>

      <li {...{
        "parentName": "ul"
      }}><a {...{
          "href": "/docs/pkg/core",
          "parentName": "li"
        }}><inlineCode {...{
            "parentName": "a"
          }}>{`cssstats`}</inlineCode></a></li>


      <li {...{
        "parentName": "ul"
      }}><a {...{
          "href": "/docs/pkg/cli",
          "parentName": "li"
        }}><inlineCode {...{
            "parentName": "a"
          }}>{`cli`}</inlineCode></a></li>


      <li {...{
        "parentName": "ul"
      }}><a {...{
          "href": "/docs/pkg/wayback",
          "parentName": "li"
        }}><inlineCode {...{
            "parentName": "a"
          }}>{`wayback`}</inlineCode></a></li>

    </ul>
    <p><a {...{
        "href": "https://github.com/cssstats/cssstats/tree/master/packages",
        "parentName": "p"
      }}>{`See all packages `}
        {`→`}</a></p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      